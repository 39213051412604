.cloud-container {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 450px; /* Adjust width as needed */
    height: 640px; /* Adjust height as needed */
    
  }
  
  .cloud-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: inset(0 21% 0 0% round 4% 20% 0 10%);
  }
  